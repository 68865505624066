import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

import enJSON from './locale/eng.json';
import frJSON from './locale/fr.json';
import esJSON from './locale/es.json';
import arJSON from './locale/ar.json';

i18n.use(initReactI18next).init({
    resources: {
        en: { translation: { ...enJSON } },
        fr: { translation: { ...frJSON } },
        es: { translation: { ...esJSON } },
        ar: { translation: { ...arJSON } },
    },
    lng: "en",
});

