import React, { useState } from 'react';
import { ArrowDropUp, ArrowDropUpOutlined, ArrowUpward, DarkMode, Language, Lightbulb, SignLanguageOutlined } from '@mui/icons-material';
import pseudoNameType from '../helpers/types/pseudoName';
import { ES, FR, SA, US } from 'country-flag-icons/react/3x2'

const NavBar = (props: {
    pseudoName: pseudoNameType,
    toggleDarkMode: Function,
    handleChangeLanguage: Function,
}) => {
    const [langMenu, setLangMenu] = useState<boolean>(false);

    return (
        <nav className="bg-primary-100 border-primary-100 dark:bg-primary-950 shadow-sm dark:shadow-primary-700 shadow-primary-500">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                    <img src="/logo.png" className="h-8" alt="dardacha.live" />
                    <span className="hidden md:block self-center text-2xl font-semibold whitespace-nowrap">Talky Walky</span>
                </a>
                <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm rounded-lg md:hidden hover:bg-primary-100 focus:outline-none focus:ring-2 focus:ring-primary-200 dark:text-primary-400 dark:hover:bg-primary-700 dark:focus:ring-primary-600" aria-controls="navbar-default" aria-expanded="false">
                    <span className="sr-only">Open main menu</span>
                </button>
                <div className=" md:block w-auto" id="navbar-default">
                    <ul className="font-medium flex p-4 rounded-lg flex-row space-x-8 rtl:space-x-reverse mt-auto border-0">
                        <li className='mt-auto '>
                            <p className='block rounded md:bg-transparent md:p-0'>{props.pseudoName.value}</p>
                        </li>
                        <li className='mt-auto '>
                            <p className='block rounded md:bg-transparent md:p-0'>
                                <DarkMode className='cursor-pointer' onClick={() => props.toggleDarkMode()} />
                            </p>
                        </li>
                        <li className='mt-auto relative'>
                            <button className='' onClick={() => setLangMenu(!langMenu)}>
                                <Language className='text-primary-700 hover:text-primary-900 dark:text-primary-50 dark:hover:text-primary-200 transition' />
                            </button>
                            {
                                langMenu
                                    ? <div className='absolute top-8 -left-24 bg-primary-200 shadow-lg shadow-primary-300 dark:bg-primary-700 dark:shadow-primary-600 p-2 rounded'>
                                        <ArrowDropUp className='absolute -top-3 right-0 text-primary-300 dark:text-primary-700' />
                                        <ul className='pr-4'>
                                            <li onClick={() => { props.handleChangeLanguage('en'); setLangMenu(false) }} className='w-max flex py-1 text-primary-600 hover:text-primary-900 dark:text-primary-100 dark:hover:text-primary-300 cursor-pointer'>
                                                <span className='mr-2 w-[60px]'>English</span>
                                                <US title="United States" className="w-4 h-4 m-auto" />
                                            </li>
                                            <li onClick={() => { props.handleChangeLanguage('fr'); setLangMenu(false) }} className='w-max flex py-1 text-primary-600 hover:text-primary-900 dark:text-primary-100 dark:hover:text-primary-300 cursor-pointer'>
                                                <span className='mr-2 w-[60px]'>Fran&ccedil;ais</span>
                                                <FR title="Fran&ccedil;ais" className="w-4 h-4 m-auto" />
                                            </li>
                                            <li onClick={() => { props.handleChangeLanguage('es'); setLangMenu(false) }} className='w-max flex py-1 text-primary-600 hover:text-primary-900 dark:text-primary-100 dark:hover:text-primary-300 cursor-pointer'>
                                                <span className='mr-2 w-[60px]'>Espa&ntilde;ol</span>
                                                <ES title="Espa&ntilde;ol" className="w-4 h-4 m-auto" />
                                            </li>
                                            <li onClick={() => { props.handleChangeLanguage('ar'); setLangMenu(false) }} className='w-max flex py-1 text-primary-600 hover:text-primary-900 dark:text-primary-100 dark:hover:text-primary-300 cursor-pointer'>
                                                <SA title="Arabic" className="w-4 h-4 m-auto " />
                                                <span className='ml-4 w-[60px]'>العربية</span>
                                            </li>
                                        </ul>
                                    </div>
                                    : <></>
                            }
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavBar;