import { Call, PictureInPicture, VolumeMute, VolumeOff } from '@mui/icons-material';
import React, { useRef, useEffect, useState } from 'react';

const PiPVideoCall = (props: { remoteStream: MediaStream, localStream: MediaStream | null, hangOut: Function }) => {
    const { remoteStream, localStream } = props;
    const videoRef = useRef<HTMLVideoElement | null>(null); // Ref for the video element
    const localVideoRef = useRef<HTMLVideoElement | null>(null); // Ref for the video element
    const [videoControls, setVideoControls] = useState({
        muted: false,
    });

    // Attach the remote stream to the video element when the component mounts
    useEffect(() => {
        if (videoRef.current && remoteStream && localVideoRef.current && localStream) {
            videoRef.current.srcObject = remoteStream;
            localVideoRef.current.srcObject = localStream;
        }
        console.log('PiP.useEffect')
    }, [remoteStream]);

    // Function to enable Picture-in-Picture mode
    const enablePiP = async () => {
        const video = videoRef.current;

        if (document.pictureInPictureEnabled && video) {
            try {
                if (video !== document.pictureInPictureElement) {
                    await video.requestPictureInPicture();
                    console.log('Picture-in-Picture mode started');
                } else {
                    await document.exitPictureInPicture();
                    console.log('Exited Picture-in-Picture mode');
                }
            } catch (error) {
                console.error('Error enabling PiP mode:', error);
            }
        } else {
            console.log('Picture-in-Picture is not supported by this browser.');
        }
    };

    return (
        <div data-dialog-backdrop="dialog-lg" data-dialog-backdrop-close="true" className="fixed inset-0 z-[999] grid h-screen w-screen place-items-center bg-primary-300 dark:bg-primary-700 bg-opacity-60 backdrop-blur-sm transition-opacity duration-300" tabIndex={0}>
            <div data-dialog="dialog-lg" className={[
                "p-2 relative m-4 w-2/3 h-2/3 rounded-lg leading-relaxed antialiased shadow-2xl transition-all duration-300 opacity-1 translate-y-0",
                "text-primary-700 dark:text-primary-100 bg-primary-300 dark:bg-primary-600 font-sans text-base font-light"
            ].join(' ')}>
                <div className='absolute top-1 right-1 z-[100]'>
                    <button onClick={enablePiP} className="cursor-pointer p-1 m-1">
                        <PictureInPicture className="text-primary-700 dark:text-primary-100" />
                    </button>
                    <button onClick={() => setVideoControls((prev) => ({ ...prev, muted: !prev.muted }))} className="cursor-pointer p-1 m-1">
                        {
                            videoControls.muted
                                ? <VolumeMute className='text-primary-700 dark:text-primary-100' />
                                : <VolumeOff className='text-red-500' />
                        }
                    </button>
                    <button role="close" className="cursor-pointer p-1 m-1" onClick={() => props.hangOut()}>
                        <Call className="text-red-500 hover:text-red-300 transition" />
                    </button>
                </div>

                <div className="h-full w-full relative bg-white dark:bg-primary-950">
                    <video
                        ref={videoRef}
                        autoPlay
                        {...videoControls}
                        playsInline
                        style={{ width: '100%', height: '100%' }}
                    ></video>

                    <div className="absolute bottom-4 right-4 w-[150px] h-[150px] rounded-full bg-primary-300">
                        <video
                            ref={localVideoRef}
                            autoPlay
                            muted
                            playsInline
                            style={{ width: '100%', height: '100%', borderRadius: '50%' }}
                        ></video>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default PiPVideoCall;
