import { CloseRounded, Message } from '@mui/icons-material';
import toast, { Toaster } from 'react-hot-toast';

const toastNow = (payload: { message: string | String }) => {
    toast.custom((t) => (
        <div
            className={`${t.visible ? 'animate-enter' : 'animate-leave'
                } max-w-md w-full bg-primary-100 text-primary-700 dark:bg-primary-700 dark:text-primary-100 shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
        >
            <div className="flex-1 w-0 p-4">
                <div className="flex items-start">
                    <div className="flex-shrink-0 pt-auto">
                        <Message />
                    </div>
                    <div className="ml-3 flex-1">
                        <p className="mt-1 text-sm text-gray-500">
                            {payload.message}
                        </p>
                    </div>
                </div>
            </div>
            <button
                onClick={() => toast.dismiss(t.id)}
                className="items-center justify-center focus:outline-none"
            >
                <CloseRounded className="p-1 rounded-full bg-primary-100 dark:bg-primary-800 w-8 h-8" />
            </button>
        </div>
    ))
}

export default toastNow;