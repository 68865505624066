import Peer, { DataConnection } from "peerjs";
import chatMemberType from "../helpers/types/chatMember";
import pseudoNameType from "../helpers/types/pseudoName";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import PiPVideoCall from "./PiPVideoCall";

const VideoCall = forwardRef((props: {
    member: chatMemberType,
    peer: Peer | null,
    peerConnection: DataConnection | null,
    pseudoName: pseudoNameType,
    hangOut: Function,
    callStatus: String,
}, ref) => {
    const localStreamRef = useRef<HTMLVideoElement>(null);
    const remoteStreamRef = useRef<HTMLVideoElement>(null);
    const [videoStream, setVideoStream] = useState<MediaStream | null>(null);
    const [localStream, setLocalStream] = useState<MediaStream | null>(null);

    useEffect(() => {
        receiver();
    })

    const caller = () => {
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
            .then(stream => {
                // Display the local video

                // if (!localStreamRef.current) return;
                // localStreamRef.current.srcObject = stream;
                setLocalStream(stream);

                // Call the other peer
                const call = props.peer?.call(props.member.pseudoNameHash.toString(), stream);

                call?.on('stream', (remoteStream) => {
                    // Display the remote video

                    // if (!remoteStreamRef.current) return;
                    // remoteStreamRef.current.srcObject = remoteStream;
                    setVideoStream(remoteStream);
                });
            })
            .catch(err => {
                console.error('Failed to get local stream:', err);
            });
    }

    const receiver = () => {
        props.peer?.on('call', (call) => {
            navigator.mediaDevices.getUserMedia({ video: true, audio: true })
                .then(stream => {
                    // Display the local video

                    // if (!localStreamRef.current) return;
                    // localStreamRef.current.srcObject = stream;
                    setLocalStream(stream);

                    // Answer the call
                    call.answer(stream);

                    call.on('stream', (remoteStream) => {
                        // Display the remote video
                        console.log('received call')
                        setVideoStream(stream);

                        // if (!remoteStreamRef.current) return;
                        // remoteStreamRef.current.srcObject = remoteStream;
                    });
                })
                .catch(err => {
                    console.error('Failed to get local stream:', err);
                });
        });

    }

    useImperativeHandle(ref, () => ({
        toggleCall() {
            if (props.callStatus === 'off') {
                caller();
            } else {
                hangOut()
            }
        }
    }))

    const hangOut = () => {
        setVideoStream(null);
        props.hangOut();
    }

    const call = () => {
        /* Setting up the constraint */
        var facingMode = "user"; // Can be 'user' or 'environment' to access back or front camera (NEAT!)
        var constraints = {
            audio: false,
            video: {
                facingMode: facingMode
            }
        };

        if (!navigator.mediaDevices) {
            console.warn('Cannot get mediaDevices!');
            return;
        };

        /* Stream it to video element */
        navigator.mediaDevices.getUserMedia(constraints).then(function success(stream) {
            // video.srcObject = stream;
            if (localStreamRef.current) {
                localStreamRef.current.srcObject = stream;

                var call = props.peer?.call('dest-peer-id',
                    stream);
            }
        });
    }

    return (
        <div className="">
            {videoStream && <PiPVideoCall hangOut={hangOut} remoteStream={videoStream} localStream={localStream} />}

            <div className="hidden fixed top-1/4 bg-red-500">
                <button className="" onClick={(() => caller())}>Call</button>
                <video ref={localStreamRef} className="w-full h-full border border-amber-300 rounded-lg"
                    autoPlay={true}
                    playsInline={true}
                />
                <video ref={remoteStreamRef} className="w-[200px] h-[200px] border border-emerald-300 rounded-lg"
                    autoPlay={true}
                    playsInline={true}
                />
            </div>
        </div>
    )
})



export default VideoCall;