import { DataConnection, Peer } from 'peerjs';
import chatMemberType from './types/chatMember';

class PeerClient {
    serverUri: string = '';
    client: Peer;
    public peersConnection: DataConnection[] | null;
    private static instance: PeerClient;
    private static delimeters = ['a', 'e'];

    private constructor(clientId: string) {
        this.client = new Peer(clientId);
        this.peersConnection = [];
    }


    public static getInstance(clientId: string | null) {
        if (this.instance || !clientId) return this.instance;

        this.instance = new PeerClient(clientId);
        return this.instance;
    }

    /**
     * Connect to server in order to make myself online
     */
    public connectToPeerServer() {
        // 
    }

    /**
     * Connect to peer client to start chatting with him
     * @param peerID string
     */
    public connectToPeerClient(peerID: string) {
        // 
    }

    /**
     * Returns peer client to start chatting with him
     */
    public getPeerClientConnection(peer: chatMemberType): DataConnection | null {
        if (this.peersConnection) {
            for (let peerConn of this.peersConnection) {
                if (peerConn?.peer == peer.pseudoName) return peerConn;
            }
        }

        return null;
    }


    public static HashString = (pseudoNameValue: String, shouldRandomize = false): String => {
        // Randomize the PeerID by include the current TimeStamp in the generated PeerID to ensure its uniqueness
        // This suppoze to only executes on creating new Peer instance (new Peer())
        // which will ensure the uniqueness of the PeerID
        if (shouldRandomize) {
            const currentTime = (new Date()).getTime();
            pseudoNameValue = `${pseudoNameValue}[${currentTime}]`;
        }

        const pseudoChars = pseudoNameValue.split('').map(char => `${PeerClient.delimeters[0]}${char.charCodeAt(0)}${PeerClient.delimeters[1]}`)
        return pseudoChars.join('');
    }

    public static UnhashString = (sanitizedPseudoNameValue: String | string): String => {
        // const expr = /\{[0-9]+\}/g;
        const expr = `/${PeerClient.delimeters[0]}[0-9]+${PeerClient.delimeters[1]}/g`;

        const occurences = sanitizedPseudoNameValue.match(eval(expr));
        occurences?.forEach(match => {
            let code = parseInt(match.slice(PeerClient.delimeters[0].length, PeerClient.delimeters[1].length * -1));
            sanitizedPseudoNameValue = sanitizedPseudoNameValue.replace(match, String.fromCharCode(code));
        });

        // Exclude the appended TimeStamp in the generated PeerIDs for uniqueness guarantee
        const cleanPseudoName = sanitizedPseudoNameValue.replace(/\[[0-9]+\]$/, '');

        return cleanPseudoName;
    }
}


export default PeerClient;