import { StrictMode, createContext, useEffect, useRef, useState } from 'react';
import NavBar from './components/navbar';
import pseudoNameType from './helpers/types/pseudoName';
import chatMemberType from './helpers/types/chatMember';
import './App.css';
import { darkColors } from './helpers/colors';
import Conversation from './components/conversation';
import Peer, { DataConnection } from 'peerjs';
import { ArrowForward, RefreshOutlined } from '@mui/icons-material';

import { useTranslation } from "react-i18next";
import './i18n';
import PeerClient from './helpers/peerClient';
import RoomMemberRow from './components/RoomMemberRow';

const ThemeContext = createContext('dark');
export { ThemeContext };

function App() {
  const [sessionCheck, setSessionCheck] = useState(true);

  const pseudonameInput = useRef<HTMLInputElement>(null);

  const [pseudoName, setPseudoName] = useState<pseudoNameType>({
    value: '',
    hash: '',
    is_confirmed: false, // When the user press 'join chat room' button
  });

  const [darkMode, setDarkMode] = useState<string | null>('dark');


  const [pseudoNameSearch, setPseudoNameSearch] = useState('');
  const [roomMembers, setRoomMembers] = useState<chatMemberType[]>([]);

  const [chosenMember, setChosenMember] = useState<chatMemberType | null>(null);

  const [peer, setPeer] = useState<Peer | null>(null);
  const [peerConnection, setPeerConnection] = useState<DataConnection | null>(null);

  const PEER_JS_HOST = process.env.REACT_APP_PEER_JS_HOST;
  const PEER_JS_PORT = process.env.REACT_APP_PEER_JS_PORT;

  const [contactsList, setContactsList] = useState<chatMemberType[]>([]);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    // Get username from localStorage
    const pseudoname = localStorage.getItem('pseudoname');
    if (pseudoname) {
      setPseudoName((prev) => ({
        value: PeerClient.UnhashString(pseudoname),
        hash: pseudoname,
        is_confirmed: true,
      }));
    }

    // Dark mode detection
    const _darkMode = localStorage.getItem('darkMode');
    setDarkMode(_darkMode ? _darkMode : darkMode);


    detectLang();

    if (pseudoname) {
      initPeer();
    }

    setSessionCheck(false);


    const intervalID = setInterval(() => {
      if (peer && roomMembers) {
        clearInterval(intervalID);
        checkForOpenConversation();
      }
    }, 200);


    // Retrieve contact list
    restoreContactsList();
    setFirstLoad(false);
    // Clean up
    return (() => {
      // peerConnection?.close();
      // peer?.destroy();
      // setPeer(null)
      // setPeerConnection(null)
    })
  }, [peer, roomMembers]);


  const initPeer = () => {
    if (peer || peerConnection) return;
    const sanitizedPseudoName = localStorage.getItem('pseudoname') ?? '';

    const _peer = new Peer(`${sanitizedPseudoName}`, {
      host: PEER_JS_HOST,
      port: PEER_JS_PORT ? parseInt(PEER_JS_PORT) : 443,
      path: '/',
      secure: true,
    });

    _peer.on('open', (id) => {
      if (!peer) {
        setPeer((peer) => _peer);
        checkForOnlineMembers();

        // re executes every 5s
        setInterval(() => {
          checkForOnlineMembers();
        }, 3000);
      }

      _peer.on("connection", (conn) => {
        if (!peerConnection) {
          setPeerConnection(conn);

          conn.on('data', (data: any) => {
            const message = {
              id: data.id,
              text: data.text,
              sent_at: data.sent_at,
            };
            setRoomMembers((prev) => prev.map(member => member.pseudoNameHash == data.sender ? { ...member, unreadMessages: [...member.unreadMessages, message] } : member));
          })
        }
      });

    })
  }

  const checkForOpenConversation = () => {
    // Detect if the conversation has already been initialized
    const chatMember = localStorage.getItem('chatMember');
    let chatMemberPseudoName = '';
    if (chatMember) {
      chatMemberPseudoName = JSON.parse(chatMember).pseudoName;
      const index = roomMembers.findIndex(member => member.pseudoName === chatMemberPseudoName);
      if (index >= 0) {
        startConversation(roomMembers[index]);
      }
    }
  }

  const pingPeer = (peerHashedID: String) => {
    ////////////
    if (peerConnection) {
      return;
    }

    const connection = peer?.connect(`${peerHashedID}`);
    connection?.on('open', () => {
      if (!peerConnection) setPeerConnection(connection)
    });
  }

  const [refreshingMembers, setRefreshingMembers] = useState(false);
  const checkForOnlineMembers = async () => {
    setRefreshingMembers(true);
    fetch(`https://p2p.elhajouji.com:1443/peers/peers`)
      .then(res => {
        res.json().then((data: String[]) => {

          // Retrieve currently online members
          // Extract the members they're still online (residents) by keeping their unreadMessages data
          const residents = roomMembers.filter(m => data.includes(m.pseudoNameHash));
          const residentsIDs = residents.map(m => m.pseudoNameHash);

          // Extract only new members
          const newMembers: chatMemberType[] = data.filter(id => !residentsIDs.includes(id)).map(x => {
            return { pseudoName: PeerClient.UnhashString(x), pseudoNameHash: x, unreadMessages: [] };
          });

          // Spread them to the new state
          setRoomMembers([...residents, ...newMembers]);
          setRefreshingMembers(false);
          // checkForOpenConversation(members);
        })
      })
      .catch(err => {
        console.warn('err', err);
        setRefreshingMembers(false);
      })
  };

  const joinChatRoom = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (pseudoName.value.length < 4) {
      alert('Pseudo name too short, minimum 4 characters!');
      if (pseudonameInput && pseudonameInput.current) pseudonameInput.current.focus();
      return
    }

    const sanitizedPseudo = PeerClient.HashString(pseudoName.value, true);
    setPseudoName({ ...pseudoName, is_confirmed: true, hash: sanitizedPseudo.toString() });
    localStorage.setItem('pseudoname', sanitizedPseudo.toString());
  };

  const startConversation = (member: chatMemberType) => {
    localStorage.setItem('chatMember', JSON.stringify(member));
    setChosenMember(member);

    pingPeer(member.pseudoNameHash);

    saveToContactList(member);

    // Mark unread messages as read if there're
    setRoomMembers((prev) => prev.map(x => x.pseudoNameHash == member.pseudoNameHash ? { ...x, unreadMessages: [] } : x));
  }

  //[ Contacts list history
  const saveToContactList = (contact: chatMemberType) => {
    const index = contactsList.findIndex(x => x.pseudoNameHash === contact.pseudoNameHash);
    if (index >= 0) return;

    const newState = [...contactsList, contact];
    setContactsList(newState);
    localStorage.setItem('contactLists', JSON.stringify(newState));
  }

  const restoreContactsList = () => {
    // This should only executes on first load
    if (!firstLoad) return;

    const old = localStorage.getItem('contactLists');
    if (!old) return;

    const __ = JSON.parse(old ?? '');
    const list: chatMemberType[] = __.map((item: any) => ({
      pseudoName: item.pseudoName,
      pseudoNameHash: item.pseudoNameHash,
      unreadMessages: item.unreadMessages.length < 1 ? [] : item.unreadMessages.map((m: any) => ({
        id: m.id,
        text: m.text,
        sent_at: m.sent_at,
      })),
    }));

    setContactsList(list);
  }
  //]

  const leaveConversation = () => {
    if (!chosenMember) return;
    localStorage.removeItem('chatMember');
    setChosenMember(null);
  }

  const toggleDarkMode = () => {
    const newVal = (darkMode === 'dark') ? 'light' : 'dark';
    setDarkMode(prev => (newVal));
    localStorage.setItem('darkMode', newVal);
  }

  const renderPseudoNameForm = () => {
    if (pseudoName.is_confirmed) return;
    return (
      <form className='p-4 h-screen md:w-1/3 m-auto' onSubmit={(el) => joinChatRoom(el)}>
        <div className='my-2'>
          <label className="block p-1 my-2 text-base" htmlFor='pseudoname' >{t('typePseudoName')}</label>
          <input className="block rounded-md py-2 px-1 outline-0 border-b border-primary-500 dark:bg-primary-700" ref={pseudonameInput} type='text' name="pseudoname" id="pseudoname" value={pseudoName.value.toString()} onChange={(e) => setPseudoName({ ...pseudoName, value: e.target.value })} />
        </div>

        <div className=''>
          <button type='submit' className='bg-primary-300 text-primary-900 hover:bg-primary-400 dark:bg-primary-700 dark:text-primary-100 px-4 py-2 rounded-sm dark:hover:bg-primary-800 active:bg-primary-900 transition-all outline-none'>
            <span className=''>{t('joinChat')}</span>
            <ArrowForward className='' />
          </button>
        </div>
      </form>
    );
  }


  const getBgHexaColorFromName = (name: String): String => {
    const index = name.split('').reduce((carry, val) => { return carry += val.charCodeAt(0) }, 0) % darkColors.length
    return darkColors[index];
  }

  const generateShortName = (name: String): String => {
    return [name[0], name[name.length - 1]].join('').toUpperCase();
  }

  const filterRoomMember = (member: chatMemberType) => {
    return (member.pseudoName != pseudoName.value && member.pseudoName.trim().includes(pseudoNameSearch));
  }

  const renderChatRoomMembers = () => {
    if (!pseudoName.is_confirmed || chosenMember) return <></>;

    const absentMembers = contactsList.filter(x => !(roomMembers.map(y => y.pseudoNameHash)).includes(x.pseudoNameHash));

    return (
      // Container
      <div className='p-4 h-screen md:w-1/3 m-auto'>
        <h2 className='text-xl text-center flex mb-4'>
          <span className=''>{t('onlineMembers')}</span>
          <button onClick={checkForOnlineMembers} title={t('checkNewMembers')}
            className={['ml-2 dark:text-primary-300 dark:hover:text-primary-100 text-primary-700 hover:text-primary-950 transition-all ', refreshingMembers ? 'rotate' : ''].join('')}>
            <RefreshOutlined className='' />
          </button>
        </h2>

        {/* Search members */}
        <div className="">
          <input onChange={e => setPseudoNameSearch(e.target.value)} value={pseudoNameSearch} placeholder={t('pseudoNameSearch')}
            className='w-full mb-2 rounded-md p-2 pl-1 outline-0 border-0 bg-primary-50 hover:bg-primary-100 dark:bg-primary-900 dark:hover:bg-primary-800'
          />
        </div>
        {/* Online members */}
        {
          [...roomMembers, ...absentMembers].filter(x => filterRoomMember(x)).length < 1
            ? <div className='w-full text-center py-4'>
              <p className=''>{t('roomMemberIsEmpty')}</p>
            </div>
            : roomMembers.filter(x => filterRoomMember(x)).map((member, index) => (
              <RoomMemberRow isOnline={true} member={member} key={index} startConversation={startConversation} />
            ))
        }

        {/* Old conversations history */}
        {
          absentMembers.filter(x => filterRoomMember(x)).map((member, index) => (
            <RoomMemberRow isOnline={false} member={member} key={index} startConversation={startConversation} />
          ))
        }
      </div>
    );
  }

  /**
   * Internationalization
   */
  const { t, i18n: { changeLanguage, language } } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(language)
  const handleChangeLanguage = (newLanguage: string) => {
    setCurrentLanguage(newLanguage);
    changeLanguage(newLanguage);
    localStorage.setItem('_lang', newLanguage);
  }

  const detectLang = () => {
    const lang = localStorage.getItem('_lang');
    setCurrentLanguage(lang ?? 'en');
    changeLanguage(lang ?? 'en');
  }
  ///////////

  return (
    <StrictMode>
      <ThemeContext.Provider value={darkMode ? darkMode : 'dark'}>
        <div className={["bg-white dark:bg-primary-950 h-screen m-0 p-0 relative", darkMode === 'dark' ? 'dark' : ''].join(' ')}>
          {
            sessionCheck ? <div className=''>{t('sessionCheck')}</div>
              :
              <div className='bg-white text-primary-900 dark:bg-primary-950 dark:text-primary-50'>
                {/* Navbar */}
                <NavBar handleChangeLanguage={handleChangeLanguage} pseudoName={pseudoName} toggleDarkMode={toggleDarkMode} />

                {/* Pseudo name form */}
                {renderPseudoNameForm()}

                {/* Chat room members */}
                {renderChatRoomMembers()}


                {
                  (chosenMember) ? <Conversation leaveConversation={leaveConversation} member={chosenMember} pseudoName={pseudoName} peer={peer} peerConnection={peerConnection} /> : <></>
                }
              </div>
          }
        </div>
      </ThemeContext.Provider>
    </StrictMode>
  );
}


export default App;
